<template>
  <div
    class="guide-carousel glide"
    :class="{ 'guide-carousel--with-decoration': decoration }"
  >
    <div
      :class="['glide__track', arrows && 'glide__track--with-arrow']"
      data-glide-el="track"
    >
      <ul class="glide__slides">
        <slot></slot>
      </ul>
    </div>
    <div
      v-if="arrows == 'default'"
      class="guide-carousel__arrows"
      data-glide-el="controls"
    >
      <button
        class="guide-carousel__arrow guide-carousel__arrow--default guide-carousel__arrow--left"
        data-glide-dir="<"
      ></button>
      <button
        class="guide-carousel__arrow guide-carousel__arrow--default guide-carousel__arrow--right"
        data-glide-dir=">"
      ></button>
    </div>
    <div v-if="!hideBullet" class="guide-carousel__bullets-container">
      <div class="guide-carousel__controls">
        <div data-glide-el="controls" class="guide-carousel__arrows">
          <button
            v-if="arrows == 'small'"
            class="guide-carousel__arrow guide-carousel__arrow--small guide-carousel__arrow--left"
            data-glide-dir="<"
          ></button>
          <button
            v-if="arrows == 'small'"
            class="guide-carousel__arrow guide-carousel__arrow--small guide-carousel__arrow--right"
            data-glide-dir=">"
          ></button>
        </div>
        <div class="guide-carousel__bullets" data-glide-el="controls[nav]">
          <button
            v-for="(i, index) in bulletCount"
            :key="index"
            class="guide-carousel__bullet"
            :data-glide-dir="'=' + index"
          ></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';
import { findParent } from '../../fns/element-manipulations';

export default {
  name: 'Carousel',
  props: {
    decoration: {
      type: Boolean,
      default: false,
    },
    arrows: {
      type: String,
      validator(val) {
        return ['default', 'small'].includes(val);
      },
    },
    hideBullet: {
      type: Boolean,
      default: false,
    },
    shortsetBullets: {
      type: Boolean,
      default: false,
    },
    countPerSlide: {
      type: Number,
      default: 1,
    },
    autoplay: {
      type: Number,
      default: 0,
    },
    focusAt: {
      type: [String, Number],
      default: 'center',
    },
    type: {
      type: String,
      default: 'carousel',
    },
    breakpoints: {
      type: Object,
    },
    gap: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return {
      glide: undefined,
      bulletCount: 0,
    };
  },
  mounted() {
    this.init();
  },
  updated() {
    if (this.glide) {
      this.glide.destroy();
      this.glide = null;
    }
    this.init();
  },
  computed: {},
  methods: {
    init() {
      if (!this.getSlidesCount() || this.glide) {
        return;
      }
      this.glide = new Glide(this.$el, {
        type: this.type,
        focusAt: this.focusAt,
        autoplay: this.autoplay,
        hoverpause: true,
        perView: this.countPerSlide,
        gap: this.gap,
        breakpoints: this.breakpoints,
        bound: true,
        dragThreshold: false,
      });
      this.glide.on('resize', () => {
        this.bulletCount = this.getBulletsCount();
        //console.log('glide resized', this.glide.settings.perView);
      });
      this.glide.mount();
      this.bulletCount = this.getBulletsCount();
      this.addEventListenerToSlide();
    },

    getSlidesCount() {
      return this.$slots['default'] ? this.$slots['default'].length : 0;
    },

    onClick(inx) {
      this.$emit('slide-click', Number(inx));
    },

    getBulletsCount() {
      const slidesCount = this.getSlidesCount();
      return this.shortsetBullets
        ? 1 + slidesCount - Math.floor(this.glide.settings.perView)
        : slidesCount;
    },

    addEventListenerToSlide() {
      let slides = this.$el.querySelectorAll('.glide__slide');

      slides = Array.from(slides);

      slides.forEach((el) => {
        el.addEventListener('click', (evt) => {
          const slideEl = findParent(evt.target, (el) =>
            el.classList.contains('glide__slide')
          );
          if (slideEl != null) {
            const index = slideEl.dataset.glideIndex;
            if (index != undefined) {
              this.onClick(Number(index));
            }
          }
        });
      });
    },
  },
};
</script>

<style scoped></style>
